<template>
  <el-dialog :title="title" :visible.sync="show" class="choose-selectedPlan-import">
    <el-form :model="form.data" label-width="150px" :rules="form.rules" ref="form">
      <el-form-item label="选任单位：">
        <el-select v-model="form.data.xrdw" placeholder="请选择选任单位" disabled>
          <el-option label="区域一" value="shanghai"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年度" prop="jhksnd">
        <el-date-picker v-model="form.data.jhksnd" type="year" placeholder="请选择选任开始年度" disabled></el-date-picker>
      </el-form-item>
      <el-form-item label="模板下载：">
        <a :href="form.data.href">人民陪审员报名人员模板.xls</a>
      </el-form-item>
      <el-form-item label="附件资料：">
        <div class="attach">
          <el-button type="primary" @click="StartSelectFile">上传</el-button>
          <div class="item" v-for="item in form.data.attach" :key="item.id">
            <div class="name">{{item.name}}</div>
            <i class="el-icon-circle-close" @click="removeAttach(item)"></i>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
    <input type="file" ref="file" class="inputfile" v-if="selectFile" @change="change" />
  </el-dialog>
</template>

<script>
export default {
  name: 'import',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {},
  data() {
    return {
      form: {
        data: {
          xrdw: '',
          jhksnd: '',
          attach: [],
          href: '',
        },
        rules: {
          jhksnd: [{ required: true, message: '请选择选任年度', trigger: 'change' }],
        },
      },
      show: false,
      selectFile: false,
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form.data);
        }
      });
    },
    toggle(show, data) {
      this.show = show;
      let { xrdw, jhksnd, attach } = data;
      this.form.data = {
        xrdw,
        jhksnd,
        attach,
        href: '',
      };
    },
    reset() {
      this.$refs.form.resetFields();
    },
    removeAttach(data) {
      this.$refs.file.value = '';
      this.form.data.attach.some((item, index) => {
        if (item.id === data.id) {
          this.form.data.attach.splice(index, 1);
          return true;
        }
      });
    },
    change(e) {
      let name = e.target.files[0].name;
      let id;
      if (this.form.data.attach.length === 0) {
        id = 0;
      } else {
        id = this.form.data.attach[this.form.data.attach.length - 1].id + 1;
      }
      console.log(id);
      // 校验
      let file = {
        name,
        path: '1',
        id: id,
      };
      this.form.data.attach.push(file);
      this.selectFile = false;
    },
    StartSelectFile() {
      this.selectFile = true;
      this.$nextTick(() => {
        this.$refs.file.click();
      });
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.el-dialog {
  .el-form {
    .el-date-editor {
      width: 100%;
    }
    .attach {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
    .el-select {
      width: 100%;
    }
  }
  .inputfile {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
}
</style>
